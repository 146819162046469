/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/

@font-face {
  font-family: "Lato";
  src: url("Lato-Regular.ttf");
  /* src: url("CustomFont.woff") format("woff"), */
  /* url("CustomFont.otf") format("opentype"), */
  /* url("CustomFont.svg#filename") format("svg"); */
}

body {
  margin: 0;
  padding: 0;
  font-family: Lato;
  background-color: #dce1e9;
}

h1, h2, h3, h4, h5 {
  line-height: initial
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Sidebar {
  background-color: #3a4454 !important;
  font-size: 16px !important;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.08);
  }
}

/* fix semantic-ui quirks */

.ui.header, .swagger-ui {
  color: #3a4454;
}

.ui.negative.floating.message, .ui.attached.negative.floating.message {
  box-shadow: 0px 0px 0px 1px #E0B4B4 inset, 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15)
}

.ui.vertical.menu .menu .item {
  font-size: 0.9em;
  padding: 1em;
}

.ui.vertical.menu .menu .menu .item {
  margin-left: 1em;
  font-size: 1em;
}

.ui.inverted.menu .menu a.item:hover {
  color: #ffffff;
}

.ui.button.primary {
  background-color: #007943;
  color: white
}

.ui.button.secondary {
  background-color: #3a4454;
  color: white
}

.ui.button.primary:active, .ui.button.primary:hover, .ui.button.primary:focus {
  background-color: #007943 !important;
  color: white;
  opacity: 0.8;
}

.tableRowPrimary {
  background-color: #007943;
  color: white
}

.ExplorerCategories {
  background-color: white;
  width: 100%;
  padding: 2rem !important;
}

.navBar a, .navBar div a, .navBar div div {
  font-size: 1.2rem;
  color: #556176 !important;
}

.listCategories {
  /* padding-top: 5px !important; */
  /* padding-bottom: 5px !important; */
  font-size: 2rem;
}